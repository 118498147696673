import * as React from "react"

import{appContent}from'content/links'

// import CurrencyContext,{useCurrency} from'state/context'

// import Button from'hendrixmaterial/button'

// import F from'fnuc'

// // import{TimerView,myTimer,Idk,Btn,Btn2,BtnC}from'state/mobx'


// import{HendrixObject,HendrixArray}from'state/hendrixcontext'
// import { composeContexts } from 'hendrixcontext'



// const CurrencyButton=()=>{
//   const[currency,setCurrency]=CurrencyContext.use()
//   return <Button onClick={()=>setCurrency(o=>({...F.merge(o,{Bitcoin:{code:'BTC',label:'Bitcoin',conversion:':)'}})}))}></Button>
// }

// const CurrencyDisplay=()=>{
//   const[currency,setCurrency]=CurrencyContext.use()
//   return <>{JSON.stringify(currency)}</>
// }


// // const onReady=React.useCallback(()=>{useFfmpegContext.set(o=>({...F.merge(o,{ready:true})}))},[])
// // const onReady=React.useCallback(()=>{useFfmpegContext.set(o=>({...F.merge(o,{ready:true})}))},[])
// // const onReady=React.useCallback(()=>{useFfmpegContext.mergeObject({ready:true})},[])
// // const onReady=React.useCallback(()=>{useFfmpegContext.mergeKeyWith('ready',true)},[])
// // const onReady=React.useCallback(()=>{useFfmpegContext.setKeyWith('ready',true)},[])
// // const onReady=React.useCallback(()=>{setFfmpegContext.setKeyWith('ready',true)},[])

// const HendrixButton=()=>{
//   const[obj,setObj]=HendrixObject.useContext()
//   const[arr,setArr]=HendrixArray.useContext()

//   const _obj=<>
//     {JSON.stringify(obj)||'none'}
//     <br/>
//     <Button onClick={()=>setObj.mergeObject({bitcoin:':)'})}>bitcoin</Button>
//     <Button onClick={()=>setObj.mergeKeyWith('bitcoin',':)')}>bitcoin</Button>
//     <Button onClick={()=>setObj(o=>({...o,bitcoin:':)'}))}>bitcoin</Button>
//     <Button onClick={()=>setObj.setContext(o=>({...o,bitcoin:':)'}))}>bitcoin</Button>
//   </>

//   console.log(HendrixArray.useContext())

//   const _arr=<>
//     {arr}
//     <br/>
//     <Button onClick={()=>setArr(o=>[...o,'more bitcoin :)))'])}>bitcoin</Button>
//     <Button onClick={()=>setArr.log('testtt')}>bitcoin</Button>
//   </>

//   return<>
//     {_obj}
//     <br/>
//     <br/>
//     {_arr}
//   </>
// }


function PageHome(props){
  // const[currency,setCurrency]=CurrencyContext.use()

  return <>
    {appContent}

    {/* <br/>
    <CurrencyButton/>
    <br/>
    <CurrencyDisplay/>
    <br/>
    <br/>
    <HendrixButton/> */}
  </>
}


// export default composeContexts(
//   CurrencyContext.provide,
//   HendrixObject.withContext,
//   HendrixArray.withContext
// )(PageHome)

export default PageHome
